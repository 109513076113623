@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply bg-dark text-white;

  min-width: 348px;
  background-image: url("../public/bg-l1.png"), url("../public/bg-l2.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.hero_mobile {
  width: 100vw;
  height: calc(100vw / 800 * 288);
  margin: 72px auto 52px;
  background-image: url("../public/img_hero_desktop.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.hero_desktop {
  width: 800px;
  height: 288px;
  margin: 160px auto 136px;
  background-image: url("../public/img_hero_desktop.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.link {
  @apply transition-all hover:underline hover:opacity-70;
}

.icon {
  @apply flex h-10 w-10 items-center justify-center rounded-full bg-gray-dark;
}

.input {
  @apply rounded-lg border border-gray bg-dark py-2 px-4 text-white focus:border-gray-light focus:outline-none;
}
